import { useEffect, useMemo, useState } from 'react';
import { functions } from '@wap-client/core';
import Anchor from '@/components/base/anchor/Anchor';
import { Container } from '@/components/base/gridview';
import Image from '@/components/base/image';
import Icon from '@/components/base/icon';
import SliderCards from '@/components/base/slider-cards';
import CustomButton from '@/components/widgets/custom-button';
import useDeviceType, {
  useMobileRotateDeviceType,
} from '@/hooks/useDeviceType';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Grid, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { UIBanners } from './types';
import SwiperToolbar from '@/components/base/slider-cards/slider-swiper/toolbar/SwiperToolbar';

const Banner: React.FC<UIBanners & { id: string }> = ({
  data = [],
  showItems,
  id,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 30,
  mtMobile = 30,
}) => {
  const deviceType = useDeviceType();
  const checkRotate = useMobileRotateDeviceType(500);
  const [swiperKey, setSwiperKey] = useState(0);

  const bannerID = id;
  const selectors = useMemo(() => {
    if (!bannerID) {
      return null;
    }
    return {
      toolbar: `toolbar-${bannerID}`,
      navigation: {
        prev: `prev-${bannerID}`,
        next: `next-${bannerID}`,
      },
      pagination: `pagination-${bannerID}`,
    };
  }, [bannerID]);

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [deviceType, checkRotate]);

  const defaultSliderSettings: SwiperOptions = {
    modules: [Grid, Pagination, Navigation],
    slideToClickedSlide: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      520: {
        slidesPerView:
          data.length > 1 && showItems && showItems > 1
            ? parseFloat(`${showItems}.1`)
            : showItems,
        spaceBetween: 20,
      },
    },
  };

  const completedSliderSettings = useMemo<SwiperProps>(
    () => ({
      ...defaultSliderSettings,
      onInit: (swiper) => {
        if (selectors?.toolbar) {
          const findedToolbarEl = document.querySelector(
            `.${selectors.toolbar}`
          );
          if (findedToolbarEl) {
            findedToolbarEl.classList.add('swiper-initialized');
          }
        }
      },
      navigation: {
        nextEl: selectors
          ? `.${selectors.navigation.next}`
          : '.swiper-toolbar-prev',
        prevEl: selectors
          ? `.${selectors.navigation.prev}`
          : '.swiper-toolbar-next',
      },
      pagination: {
        el: selectors
          ? `.${selectors.pagination}`
          : '.swiper-toolbar-pagination',
        type: 'bullets',
        clickable: true,
      },
    }),
    []
  );

  if (!data[0]?.image && deviceType === 'mobile') {
    return;
  }

  return (
    <section
      className="banner"
      style={{
        marginTop:
          deviceType === 'mobile'
            ? mtMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mtLaptop
            : mtDesktop,
        marginBottom:
          deviceType === 'mobile'
            ? mbMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mbLaptop
            : mbDesktop,
      }}
    >
      <Container>
        <Swiper {...completedSliderSettings} key={swiperKey}>
          {data.map((banner, index) => (
            <SwiperSlide key={index}>
              <div
                className={functions.classnames(
                  'banner-item',
                  banner.className && `banner-item--${banner.className}`,
                  data.length > 1 && `banner-item--slide`
                )}
                style={{
                  ...(banner.items &&
                  banner.items.length > 0 &&
                  deviceType !== 'mobile'
                    ? {
                        marginBottom: '120px',
                      }
                    : {}),
                }}
              >
                {deviceType !== 'mobile' ? (
                  <>
                    <div className="background-wrapper">
                      <Image {...banner.background} alt={banner.title} />
                    </div>
                    <div className="banner-item-head">
                      {banner.icon && (
                        <div className="banner-item-head-icon">
                          <Image {...banner.icon} alt={banner.title} />
                        </div>
                      )}

                      {banner.title && (
                        <h2 className="banner-item-head-title">
                          {banner.title}
                        </h2>
                      )}

                      {banner.description && (
                        <div className="banner-item-head-desc">
                          <p>{banner.description}</p>
                        </div>
                      )}

                      {banner.anchor && (
                        <CustomButton
                          iconAfter={
                            <Icon name="icon-arrow-right" size={'small'} />
                          }
                          anchor={banner.anchor}
                          round={100}
                          fontSize={14}
                          className="banner-item-head-more"
                        >
                          {banner.anchor?.children}
                        </CustomButton>
                      )}
                    </div>

                    {banner.items && banner.items.length > 0 && (
                      <div className="banner-item-cards">
                        <SliderCards
                          className={
                            data[0]?.items && data[0]?.items?.length > 0
                              ? 'swiper-toolbar-move'
                              : ''
                          }
                          cards={banner.items}
                          like={banner?.like}
                          sliderSettings={{
                            navigation: true,
                            pagination: {
                              dynamicBullets: (banner.items as []).length > 6,
                              dynamicMainBullets: 3,
                            },
                            slidesPerView: 'auto',
                            breakpoints: {
                              768: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                              820: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                              },
                              1200: {
                                slidesPerView: banner.items.length > 1 ? 2 : 1,
                                spaceBetween: 24,
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <Anchor {...banner.anchor}>
                    <Image {...banner.image} alt={banner.title} />
                  </Anchor>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {data.length > 1 && (
          <SwiperToolbar
            sliderId={bannerID}
            navigation={!!completedSliderSettings.navigation}
            pagination={!!completedSliderSettings.pagination}
          />
        )}
      </Container>
    </section>
  );
};

export default Banner;
